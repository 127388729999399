@tailwind base;
@tailwind components;
@tailwind utilities;

.Mui-error {
  margin-left: 0 !important;
}

div.ql-container.ql-snow>div.ql-editor.ql-blank>p {
  min-height: 200px !important;
}